import React from 'react';
import { Stack } from '@mui/material';
import styled from 'styled-components/macro';

export function Wrapper({ children, ...props }) {
  return <WrapperRoot {...props}>{children}</WrapperRoot>;
}

const WrapperRoot = styled.div`
  padding-left: 265px;
  padding-top: 65px;
`;

export function Content({ children, ...props }) {
  return <ContentRoot {...props}>{children}</ContentRoot>;
}

const ContentRoot = styled(Stack)`
  margin: 15px;
`;
