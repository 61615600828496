import { isMoment } from 'moment';
import { convertMomentToUnix } from './moment';

export const convertSearchStringToObject = (searchString: string): object => {
  const search = searchString.replace(/^\?/, '');
  return JSON.parse(
    '{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
    function (key, value) {
      return key === '' ? value : decodeURIComponent(value);
    },
  );
};

export const convertObjectToSearchString = (obj: object): string => {
  let searchParams = {};
  for (let key in obj) {
    if (obj[key]) {
      if (isMoment(obj[key])) {
        searchParams[key] = convertMomentToUnix(obj[key]);
      } else {
        searchParams[key] = obj[key];
      }
    }
  }
  const searchParamsObj = new URLSearchParams(searchParams);
  return searchParamsObj.toString();
};
