import TextField, { TextFieldProps } from '@mui/material/TextField';

type Params = TextFieldProps & {
  value: Date | undefined;
};

const DatePickerDisplay = (props: Params) => {
  const passDownProps = { ...props };
  if (!props.value) {
    // Little mutation to display empty string when there is yet any action
    passDownProps.inputProps!.value = '';
  }
  return <TextField {...passDownProps} />;
};

export default DatePickerDisplay;
