import React from 'react';
import { IProject } from '../slice/types';

const useHandleModal = () => {
  const [projectToEdit, setProjectToEdit] = React.useState<IProject>();
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);

  const handleModalClose = (): void => {
    setIsModalOpen(false);
    setProjectToEdit(undefined);
  };

  React.useEffect(() => {
    if (projectToEdit?.id) {
      setIsModalOpen(true);
    }
  }, [projectToEdit?.id]);

  return {
    isModalOpen,
    projectToEdit,
    setProjectToEdit,
    handleModalClose,
    createNewProject: () => setIsModalOpen(true),
  };
};

export default useHandleModal;
