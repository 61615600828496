import React, { useState } from 'react';

// UI
import styled from 'styled-components/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { LanguageSwitch } from 'app/components/LanguageSwitch';
import { ProfileBar } from 'app/components/ProfileBar';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';

import StyleWrapper from './styles';

// Redux & Sagas
import { useSelector } from 'react-redux';
import { selectGlobal } from 'app/pages/GlobalContainer/slice/selectors';
import { StopCircle } from '@mui/icons-material';

// Library
import moment from 'moment';
import _ from 'lodash';

// Utils
import {
  getRecordStartTime,
  removeRecordStartTime,
  setRecordDuration,
} from 'utils/localStorage';
import { DialogActions } from '@mui/material';

interface ITimeCounter {
  hours: number;
  minutes: number;
  seconds: number;
}

export function NavBar() {
  const [isShowProfileBar, setIsShowProfileBar] = useState<boolean>(false);
  const [
    isShowConfirmCreateNewTaskDialog,
    setIsShowConfirmCreateNewTaskDialog,
  ] = useState<boolean>(false);

  const { userProfile, pageTitle } = useSelector(selectGlobal);
  const { full_name, avatar } = userProfile;

  const [timeCounter, setTimeCounter] = React.useState<ITimeCounter>({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const [duration, setDuration] = React.useState<moment.Duration>(
    moment.duration(),
  );

  const tick = () => {
    const duration = moment.duration(moment().diff(getRecordStartTime()));
    const formatedTimeCounter = moment.utc(duration.asMilliseconds());

    setDuration(duration);

    setTimeCounter({
      hours: _.toNumber(formatedTimeCounter.format('H')),
      minutes: _.toNumber(formatedTimeCounter.format('m')),
      seconds: _.toNumber(formatedTimeCounter.format('s')),
    });
  };

  React.useEffect(() => {
    const timerId = setInterval(() => tick(), 1000);
    return () => clearInterval(timerId);
  });

  const handCreateNewTask = () => {
    const duration = moment.duration(moment().diff(getRecordStartTime()));
    setRecordDuration(duration.asHours().toString());
    removeRecordStartTime();
    setIsShowConfirmCreateNewTaskDialog(false);

    window.dispatchEvent(new Event('storage'));
  };

  return (
    <StyleWrapper>
      <NavBarRoot
        container
        flexDirection="row"
        alignItems="center"
        border="1px solid #EEF0F8"
      >
        <Grid item xs={3}>
          <Typography variant="h5" component="div">
            {pageTitle}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          {(timeCounter.hours > 0 ||
            timeCounter.minutes > 0 ||
            timeCounter.seconds > 0) && (
            <>
              <Stack direction="row" spacing={1} alignItems="center">
                <IconButton
                  color="error"
                  size="large"
                  onClick={() => setIsShowConfirmCreateNewTaskDialog(true)}
                >
                  <StopCircle fontSize="large" />
                </IconButton>

                {timeCounter.hours > 0 && (
                  <>
                    <Typography variant="h3">{timeCounter.hours}</Typography>
                    <Box component="small">h</Box>
                  </>
                )}
                <Typography variant="h3">{timeCounter.minutes}</Typography>
                <Box component="small">m</Box>
                <Typography variant="h3">{timeCounter.seconds}</Typography>
                <Box component="small">s</Box>
                <Chip
                  variant="outlined"
                  label={`Duration: ${duration.asHours().toFixed(1)}`}
                ></Chip>
              </Stack>
              <Dialog open={isShowConfirmCreateNewTaskDialog}>
                <DialogTitle>
                  <Typography variant="h5" component="div">
                    Create new task?
                  </Typography>
                </DialogTitle>
                <DialogContent>
                  Do you want to use this duration to make new task?
                </DialogContent>
                <DialogActions>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    flexGrow={1}
                  >
                    <Button
                      onClick={() => setIsShowConfirmCreateNewTaskDialog(false)}
                    >
                      Cancel
                    </Button>
                    <Box>
                      <Button
                        onClick={() => {
                          removeRecordStartTime();
                          window.dispatchEvent(new Event('storage'));
                          setIsShowConfirmCreateNewTaskDialog(false);
                        }}
                      >
                        No
                      </Button>
                      <Button onClick={handCreateNewTask}>Yes</Button>
                    </Box>
                  </Stack>
                </DialogActions>
              </Dialog>
            </>
          )}
        </Grid>
        <Grid item xs={6}>
          <Stack display="flex" flexDirection="row" justifyContent="flex-end">
            <TextField
              type="search"
              placeholder="Search"
              aria-label="Search"
              size="small"
              margin="dense"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <FontAwesomeIcon icon={faSearch} />
                  </InputAdornment>
                ),
              }}
            />
            <LanguageSwitch></LanguageSwitch>
            <Box
              onClick={() => setIsShowProfileBar(true)}
              display="inline-flex"
              alignItems="center"
            >
              <label className="welcome-name">
                Hi, <span>{full_name}</span>
              </label>
              <img
                src={avatar}
                className="img-thumbnail ms-3 avatar"
                alt="Long"
              />
            </Box>
          </Stack>
        </Grid>
      </NavBarRoot>
      <ProfileBar
        userProfile={userProfile}
        anchor="right"
        open={isShowProfileBar}
        onClose={() => setIsShowProfileBar(false)}
      />
    </StyleWrapper>
  );
}

const NavBarRoot = styled(Grid)`
  background: #fff;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  padding-left: 265px;
  z-index: 97;
  height: 65px;
  width: 100%;
  filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.05));
  & > div {
    padding: 0 10px;
  }

  .welcome-name {
    color: #b5b5c3;
    font-weight: 600;
    margin-right: 10px;
    display: inline-block;
    span {
      color: #7e8299;
    }
  }
  .avatar {
    height: 36px;
  }
`;
