import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    width: 100%;
    background-color: ${p => p.theme.background};
    font-size: 13px;
    font-weight: 400;

    -ms-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;

    color: #3F4254;
  }
  
  body.fontLoaded{
    * {
      font-family: ${p => p.theme.fontFamily} !important;
    }
  }
  
  .MuiTableContainer-root{
    background-color: #fff;
    table{
      th{
        text-transform: uppercase;
        font-weight: bold;
      }
    }
  }
`;
