import { RequestStatus } from 'constants/API';
import projectsSaga from './saga';
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { ProjectsState, IProject } from './types';
export const initialState: ProjectsState = {
  listProject: [],
  projectStatusOptions: [],
  getProjectOptionsStatus: RequestStatus.IDLE,
  getListProjectStatus: RequestStatus.IDLE,
  createProjectStatus: RequestStatus.IDLE,
  updateProjectStatus: RequestStatus.IDLE,
  deleteProjectStatus: RequestStatus.IDLE,
};

type Status = Extract<
  keyof ProjectsState,
  | 'getListProjectStatus'
  | 'createProjectStatus'
  | 'updateProjectStatus'
  | 'deleteProjectStatus'
>;

const slice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    getListProjectRequest: (state, action: PayloadAction<IProject | {}>) => {
      state.getListProjectStatus = RequestStatus.REQUESTING;
    },
    getListProjectSuccess: (state, action: PayloadAction<IProject[]>) => {
      state.getListProjectStatus = RequestStatus.SUCCESS;
      state.listProject = action.payload;
    },
    getListProjectFail: state => {
      state.getListProjectStatus = RequestStatus.ERROR;
    },

    getProjectOptionsRequest: state => {
      state.getProjectOptionsStatus = RequestStatus.REQUESTING;
    },
    getProjectOptionsSuccess: (state, action: PayloadAction<any>) => {
      state.getProjectOptionsStatus = RequestStatus.SUCCESS;
      state.projectStatusOptions = action.payload.status.choices;
    },
    getProjectOptionsFail: state => {
      state.getProjectOptionsStatus = RequestStatus.ERROR;
    },

    createProjectRequest: (state, action: PayloadAction<IProject | {}>) => {
      state.createProjectStatus = RequestStatus.REQUESTING;
    },
    createProjectSuccess: (state, action: PayloadAction<any>) => {
      state.createProjectStatus = RequestStatus.SUCCESS;
    },
    createProjectFail: state => {
      state.createProjectStatus = RequestStatus.ERROR;
    },

    updateProjectRequest: (state, action: PayloadAction<IProject | {}>) => {
      state.updateProjectStatus = RequestStatus.REQUESTING;
    },
    updateProjectSuccess: state => {
      state.updateProjectStatus = RequestStatus.SUCCESS;
    },
    updateProjectFail: state => {
      state.updateProjectStatus = RequestStatus.ERROR;
    },
    resetStatusOf: (state, action: PayloadAction<Status>) => {
      state[`${action.payload}`] = RequestStatus.IDLE;
    },

    deleteProjectRequest: (
      state,
      action: PayloadAction<{ id: IProject['id'] }>,
    ) => {
      state.deleteProjectStatus = RequestStatus.REQUESTING;
    },
    deleteProjectSuccess: state => {
      state.deleteProjectStatus = RequestStatus.SUCCESS;
    },
    deleteProjectFail: state => {
      state.deleteProjectStatus = RequestStatus.ERROR;
    },
  },
});

export const {
  getListProjectRequest,
  getListProjectSuccess,
  getListProjectFail,

  getProjectOptionsRequest,
  getProjectOptionsSuccess,
  getProjectOptionsFail,

  createProjectRequest,
  createProjectSuccess,
  createProjectFail,

  updateProjectRequest,
  updateProjectSuccess,
  updateProjectFail,

  deleteProjectRequest,
  deleteProjectSuccess,
  deleteProjectFail,
} = slice.actions;

export const useProjectsSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: projectsSaga });
  return { actions: slice.actions };
};
