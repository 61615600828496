import React, { Dispatch } from 'react';
import {
  Grid,
  Box,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEnvelope,
  faIdBadge,
  faCode,
  faSignOutAlt,
  faTasks,
  faIdCard,
} from '@fortawesome/free-solid-svg-icons';
import Path from 'config/clientPath';
import { useHistory } from 'react-router';
import { removeToken } from 'utils/localStorage';
import { IUserProfile } from 'app/pages/GlobalContainer/slice/types';
import { Action } from 'redux';
import { useDispatch } from 'react-redux';
import { logout } from 'app/pages/GlobalContainer/slice';
import StyleWrapper from './styles';

interface IProps {
  open: boolean;
  anchor: 'right' | 'left';
  userProfile: IUserProfile;
  onClose: Dispatch<Action<boolean>>;
}

export function ProfileBar(props: IProps) {
  const { userProfile, ...restProps } = props;
  const { /* (🔴 WIP) id, first_name, last_name, */ email, full_name, avatar } =
    userProfile;
  const history = useHistory();
  const dispatch = useDispatch();

  const handleLogout = (e: React.MouseEvent<HTMLLIElement>) => {
    dispatch(logout());
    removeToken();
    history.push(Path.LOGIN);
  };

  return (
    <StyleWrapper {...restProps}>
      <Grid container direction="column" style={{ width: 350, padding: 10 }}>
        <Grid item>
          <Typography gutterBottom variant="h5" component="h5">
            USER PROFILE
          </Typography>
        </Grid>
        <Divider light />
        <Grid
          container
          direction="row"
          spacing={2}
          alignItems="flex-start"
          style={{ margin: '10px 0' }}
        >
          <Grid item>
            <img src={avatar} className="img_avatar" alt={full_name} />
          </Grid>
          <Grid item xs>
            <Grid item>
              <Typography variant="h5" component="h5">
                {full_name}
              </Typography>
            </Grid>
            <Grid item>
              <Box display="flex" flexDirection="row" flexWrap="wrap">
                <Typography>
                  <FontAwesomeIcon icon={faEnvelope} />
                  {'  '}
                  {email}
                </Typography>
                <Typography style={{ margin: '0 10px' }}>
                  <FontAwesomeIcon icon={faIdBadge} />
                  {'  '}
                  Project Manager
                </Typography>
                <Typography>
                  <FontAwesomeIcon icon={faCode} />
                  {'  '}
                  PHP, Python, ...
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Divider light />
        <List>
          <ListItem>
            <ListItemIcon className="icon-profile">
              <FontAwesomeIcon icon={faIdCard} />
            </ListItemIcon>
            <ListItemText>
              My Profile
              <Typography paragraph>Update Account Infomation</Typography>
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon className="icon-tasks">
              <FontAwesomeIcon icon={faTasks} />
            </ListItemIcon>
            <ListItemText>
              My Tasks
              <Typography paragraph>Latest tasks and projects </Typography>
            </ListItemText>
          </ListItem>
          <ListItem onClick={handleLogout}>
            <ListItemIcon className="icon-signout">
              <FontAwesomeIcon icon={faSignOutAlt} />
            </ListItemIcon>
            <ListItemText>Logout</ListItemText>
          </ListItem>
        </List>
        <Divider light />
        <Grid item style={{ margin: '10px 0' }}>
          TODAY ACTIVITY
          <Typography paragraph>log1</Typography>
          <Typography paragraph>log2</Typography>
          <Typography paragraph>log3</Typography>
        </Grid>
        {/* <Offcanvas.Header className="border-top">
        <Offcanvas.Title>ACTIVITY LOGS</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <p>log1</p>
        <p>log2</p>
        <p>log3</p>
      </Offcanvas.Body> */}
      </Grid>
    </StyleWrapper>
  );
}
