import config from 'config';
import axios, { Method as AxiosMethod } from 'axios';
import { ACCESS_TOKEN_KEY } from 'constants/localStorage';

export type PaletteMethod = Extract<
  AxiosMethod,
  | 'get'
  | 'GET'
  | 'delete'
  | 'DELETE'
  | 'post'
  | 'POST'
  | 'put'
  | 'PUT'
  | 'patch'
  | 'PATCH'
  | 'options'
  | 'OPTIONS'
>;

const mainRequestConfig = {
  // Mock baseURL is from a local Postman Mock Server
  baseURL: config.serviceBaseURL,
  headers: { 'Content-Type': 'application/json' },
};

const mainAxiosInstance = axios.create(mainRequestConfig);

mainAxiosInstance.interceptors.request.use(
  config => {
    const token = localStorage.getItem(ACCESS_TOKEN_KEY);
    if (token) config.headers.Authorization = token;
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

mainAxiosInstance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    return Promise.reject(error);
  },
);

export const mainRequest = (
  url,
  payload,
  method: PaletteMethod,
  headers: Object = { 'X-Requested-With': 'XMLHttpRequest' },
) => {
  const data = payload;
  let params;
  if (method === 'get') {
    params = payload;
  }
  return mainAxiosInstance({ url, data, params, method, headers });
};

const APIs = {
  /* <AUTH> */
  signIn: payload => mainRequest('/sign-in', payload, 'get'),
  /* </AUTH> */
  /* <USER> */
  getUsers: payload => mainRequest('/user-list', payload, 'get'),
  /* </USER> */

  /* <CUSTOMER> */
  getCustomerList: payload => mainRequest('/customers/', payload, 'get'),
  createCustomerInfo: payload =>
    mainRequest('/customers/', payload, 'post', {
      'content-type': 'multipart/form-data',
    }),
  updateCustomerInfo: (id, payload) =>
    mainRequest(`/customers/${id}/`, payload, 'put', {
      'content-type': 'multipart/form-data',
    }),
  deleteCustomerInfo: payload =>
    mainRequest(`/customers/${payload}`, null, 'delete'),
  /* </CUSTOMER> */

  /* <TEAM> */
  getTeamList: payload => mainRequest('/teams/', payload, 'get'),
  createTeamInfo: payload => mainRequest('/teams/', payload, 'post'),
  updateTeamInfo: (id, payload) => mainRequest(`/teams/${id}/`, payload, 'put'),
  deleteTeamInfo: payload => mainRequest(`/teams/${payload}`, null, 'delete'),
  /* </TEAM> */

  /* <AUTH> */
  login: payload => mainRequest('/login/', payload, 'post'),
  /* </AUTH> */

  /* <PROJECTS> */
  getProjects: payload => mainRequest('projects/', payload, 'get'),
  /* </PROJECTS> */

  /* <TAGS> */
  getTags: payload => mainRequest('work_tags/', payload, 'get'),
  /* </TAGS> */

  /* <TIMESHEET> */
  getTimesheet: payload => mainRequest('work_reports/', payload, 'get'),
  addTimesheet: payload => mainRequest('work_reports/', payload, 'post'),
  updateTimesheet: (id, payload) =>
    mainRequest(`/work_reports/${id}/`, payload, 'put'),
  deleteTimesheet: payload =>
    mainRequest(`work_reports/${payload}/`, null, 'delete'),
  /* </TIMESHEET> */

  /* <INVITE> */
  createMemberFromInvite: payload => mainRequest('/members/', payload, 'post'),
  /* </INVITE> */

  getListProject: payload => mainRequest('/projects/', payload, 'get'),

  getProjectOptions: (payload = undefined) =>
    mainRequest('/projects/', payload, 'options'),

  createProject: payload => mainRequest('/projects/', payload, 'post'),

  updateProject: payload =>
    mainRequest(`/projects/${payload.id}/`, payload, 'put'),

  deleteProject: payload =>
    mainRequest(`/projects/${payload.id}`, undefined, 'delete'),
};
export default APIs;
