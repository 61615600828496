import styled from 'styled-components/macro';
import Drawer from '@mui/material/Drawer';

const StyleWrapper = styled(Drawer)`
  .MuiListItemIcon-root {
    width: 36px;
    height: 36px;
    min-width: 0;
    min-height: 0;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    margin-right: 10px;

    &.icon-profile {
      background: #fff4de;
      color: #ffa800;
    }
    &.icon-tasks {
      background: #c9f7f5;
      color: #1bc5bd;
    }
    &.icon-signout {
      background: #ffe2e5;
      color: #f64e60;
    }
  }
  p {
    margin-bottom: 0;
    color: #c4c4c4;
    font-size: 10px;
  }
  .img_avatar {
    width: 64px;
  }
`;

export default StyleWrapper;
