import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import customerSaga from './saga';
import { CustomerState } from './types';
import { RequestStatus } from 'constants/API';

export const initialState: CustomerState = {
  getCustomerListStatus: RequestStatus.IDLE,
  createCustomerInfoStatus: RequestStatus.IDLE,
  updateCustomerInfoStatus: RequestStatus.IDLE,
  deleteCustomerInfoStatus: RequestStatus.IDLE,
  listCustomer: [],
};

const slice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    getCustomerListRequest: (state, action: PayloadAction<any>) => {
      if (action) state.getCustomerListStatus = RequestStatus.REQUESTING;
    },
    getCustomerListSuccess: (state, action: PayloadAction<any>) => {
      if (action) {
        state.getCustomerListStatus = RequestStatus.SUCCESS;
        state.listCustomer = action.payload;
      }
    },
    getCustomerListFail: (state, action: PayloadAction<any>) => {
      state.getCustomerListStatus = RequestStatus.ERROR;
    },
    createCustomerInfoRequest: (state, action: PayloadAction<any>) => {
      if (action) state.createCustomerInfoStatus = RequestStatus.REQUESTING;
    },
    createCustomerInfoSuccess: (state, action: PayloadAction<any>) => {
      if (action) state.createCustomerInfoStatus = RequestStatus.SUCCESS;
    },
    createCustomerInfoFail: (state, action: PayloadAction<any>) => {
      state.createCustomerInfoStatus = RequestStatus.ERROR;
    },
    updateCustomerInfoRequest: (state, action: PayloadAction<any>) => {
      if (action) state.updateCustomerInfoStatus = RequestStatus.REQUESTING;
    },
    updateCustomerInfoSuccess: (state, action: PayloadAction<any>) => {
      if (action) state.updateCustomerInfoStatus = RequestStatus.SUCCESS;
    },
    updateCustomerInfoFail: (state, action: PayloadAction<any>) => {
      state.updateCustomerInfoStatus = RequestStatus.ERROR;
    },
    deleteCustomerInfoRequest: (state, action: PayloadAction<any>) => {
      if (action) state.deleteCustomerInfoStatus = RequestStatus.REQUESTING;
    },
    deleteCustomerInfoSuccess: (state, action: PayloadAction<any>) => {
      state.deleteCustomerInfoStatus = RequestStatus.SUCCESS;
    },
    deleteCustomerInfoFail: (state, action: PayloadAction<any>) => {
      state.deleteCustomerInfoStatus = RequestStatus.ERROR;
    },
    resetCustomerInfoRequestStatus: state => {
      state.getCustomerListStatus = RequestStatus.IDLE;
      state.createCustomerInfoStatus = RequestStatus.IDLE;
      state.updateCustomerInfoStatus = RequestStatus.IDLE;
      state.deleteCustomerInfoStatus = RequestStatus.IDLE;
    },
  },
});

export const {
  getCustomerListRequest,
  getCustomerListSuccess,
  getCustomerListFail,

  createCustomerInfoRequest,
  createCustomerInfoSuccess,
  createCustomerInfoFail,

  updateCustomerInfoRequest,
  updateCustomerInfoSuccess,
  updateCustomerInfoFail,

  deleteCustomerInfoRequest,
  deleteCustomerInfoSuccess,
  deleteCustomerInfoFail,
} = slice.actions;

export const useCustomerSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: customerSaga });
  return { actions: slice.actions };
};
