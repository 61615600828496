enum Path {
  HOME = '/',
  CONTACT = '/contact',
  CUSTOMERS = '/customers',
  PROJECTS = '/projects',
  MEMBERS = '/members',
  TIMESHEET = '/timesheet',
  TIMESHEET_REPORT = '/timesheet/report',
  TEAM = '/teams',
  LOGIN = '/login',
  FORGOT_PASSWORD = '/forgot-password',
  INVITE_MEMBER = '/join',
}

export default Path;
