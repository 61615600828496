import { StatusCode } from 'constants/API';
import { call, put, takeLatest } from 'redux-saga/effects';
import APIs from 'APIs';
import _ from 'lodash';

import {
  getCustomerListRequest,
  getCustomerListSuccess,
  getCustomerListFail,
  createCustomerInfoRequest,
  createCustomerInfoSuccess,
  createCustomerInfoFail,
  updateCustomerInfoRequest,
  updateCustomerInfoSuccess,
  updateCustomerInfoFail,
  deleteCustomerInfoRequest,
  deleteCustomerInfoSuccess,
  deleteCustomerInfoFail,
} from '.';

const {
  getCustomerList,
  createCustomerInfo,
  updateCustomerInfo,
  deleteCustomerInfo,
} = APIs;
function* getCustomerListFlow({ payload }) {
  try {
    const response = yield call(getCustomerList, payload);
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: getCustomerListSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: getCustomerListFail.type });
    }
  } catch (error) {
    yield put({ type: getCustomerListFail.type });
  }
}

function* createCustomerInfoFlow({ payload }) {
  try {
    const formData = new FormData();
    _.forEach(payload, (v, k) => {
      if (k !== 'logo') {
        formData.append(k, v);
      }
    });
    if (_.get(payload, 'logo') && _.get(payload, 'logo.name')) {
      formData.append('logo', payload.logo, payload.logo.name);
    }

    const response = yield call(createCustomerInfo, formData);
    if (response.status === StatusCode.SUCCESS_CREATED) {
      yield put({
        type: createCustomerInfoSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: createCustomerInfoFail.type });
    }
  } catch (error) {
    yield put({ type: createCustomerInfoFail.type });
  }
}

function* updateCustomerInfoFlow({ payload }) {
  try {
    const { objectId, formData } = payload;

    const _formData = new FormData();
    _.forEach(formData, (v, k) => {
      if (k !== 'logo') {
        _formData.append(k, v);
      }
    });

    if (_.get(formData, 'logo') && _.get(formData, 'logo[0].name')) {
      _formData.append('logo', formData.logo[0], formData.logo[0].name);
    }

    const response = yield call(updateCustomerInfo, objectId, _formData);
    if (response.status === StatusCode.SUCCESS_COMMON) {
      yield put({
        type: updateCustomerInfoSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({ type: updateCustomerInfoFail.type });
    }
  } catch (error) {
    yield put({ type: updateCustomerInfoFail.type });
  }
}

function* deleteCustomerInfoFlow({ payload }) {
  try {
    const response = yield call(deleteCustomerInfo, payload);
    if (response.status === StatusCode.NO_CONTENT) {
      yield put({
        type: deleteCustomerInfoSuccess.type,
      });
    } else {
      yield put({ type: deleteCustomerInfoFail.type });
    }
  } catch (error) {
    yield put({ type: deleteCustomerInfoFail.type });
  }
}

function* customerWatcher() {
  yield takeLatest(getCustomerListRequest, getCustomerListFlow);
  yield takeLatest(createCustomerInfoRequest, createCustomerInfoFlow);
  yield takeLatest(updateCustomerInfoRequest, updateCustomerInfoFlow);
  yield takeLatest(deleteCustomerInfoRequest, deleteCustomerInfoFlow);
}

export default customerWatcher;
