import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer } from 'utils/redux-injectors';
import { GlobalState, IUserProfile } from './types';
import {
  getUserProfileFromLocalStorage,
  setUserProfileIntoLocalStorage,
  getUserProfileFromSessionStorage,
  setUserProfileIntoSessionStorage,
  removeToken,
  removeUserProfileFromStorage,
} from 'utils/localStorage';

export const initialState: GlobalState = {
  isGlobalLoading: false,
  /* Messages */
  errorMessages: [],
  successMessages: [],
  infoMessages: [],
  warningMessages: [],
  isPasswordRemembered: false,
  userProfile: {
    id: '',
    email: '',
    full_name: '',
    avatar: '',
  },
  pageTitle: '',
};

const slice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setIsGlobalLoading: (state, action: PayloadAction<boolean | undefined>) => {
      if (typeof action.payload === 'boolean') {
        state.isGlobalLoading = action.payload;
      } else {
        state.isGlobalLoading = !state.isGlobalLoading;
      }
    },
    setSuccessMessages: (
      state,
      action: PayloadAction<Array<string | undefined>>,
    ) => {
      state.successMessages = action.payload;
    },
    setInfoMessages: (
      state,
      action: PayloadAction<Array<string | undefined>>,
    ) => {
      state.infoMessages = action.payload;
    },
    setWarningMessages: (
      state,
      action: PayloadAction<Array<string | undefined>>,
    ) => {
      state.warningMessages = action.payload;
    },
    setErrorMessages: (
      state,
      action: PayloadAction<Array<string | undefined>>,
    ) => {
      state.errorMessages = action.payload;
    },
    setIsPasswordRemembered: (
      state,
      action: PayloadAction<boolean | undefined>,
    ) => {
      if (action.payload === undefined) {
        state.isPasswordRemembered = !state.isPasswordRemembered;
      } else {
        state.isPasswordRemembered = action.payload;
      }
    },
    setUserProfile: (state, action: PayloadAction<IUserProfile>) => {
      state.userProfile = action.payload;
      if (state.isPasswordRemembered)
        setUserProfileIntoLocalStorage(action.payload);
      else setUserProfileIntoSessionStorage(action.payload);
    },
    retrieveProfileFromStorage: state => {
      if (state.isPasswordRemembered) {
        const userInfo = getUserProfileFromLocalStorage();
        if (userInfo) {
          state.userProfile = userInfo;
        }
      } else {
        const userInfo = getUserProfileFromSessionStorage();
        if (userInfo) {
          state.userProfile = userInfo;
        }
      }
    },
    logout: () => {
      removeToken();
      removeUserProfileFromStorage();
    },
    setPageTitle: (state, action: PayloadAction<string>) => {
      state.pageTitle = action.payload;
    },
  },
});

export const {
  setIsGlobalLoading,
  setErrorMessages,
  setInfoMessages,
  setSuccessMessages,
  setWarningMessages,
  setIsPasswordRemembered,
  setUserProfile,
  logout,
  setPageTitle,
} = slice.actions;

export const useGlobalSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  return { actions: slice.actions };
};
