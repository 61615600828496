/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import * as React from 'react';
import Path from 'config/clientPath';
import { Helmet } from 'react-helmet-async';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';
import { GlobalStyle } from 'styles/global-styles';
import { HomePage } from './pages/HomePage/Loadable';
import { LoginPage } from './pages/LoginPage/Loadable';
import ProtectedRoute from './components/ProtectedRoute';
import { GlobalContainer } from './pages/GlobalContainer';
import { CustomerPage } from './pages/CustomerPage/Loadable';
import { TimesheetPage } from './pages/TimesheetPage/Loadable';
import { TimesheetReportPage } from './pages/TimesheetReportPage/Loadable';
import { TeamPage } from './pages/TeamPage/Loadable';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import { NotFoundPage } from './components/NotFoundPage/Loadable';
import { ProjectPage } from './pages/ProjectPage';
import { InviteMemberPage } from './pages/InviteMemberPage';

export function App() {
  const { i18n } = useTranslation();
  return (
    <BrowserRouter>
      <Helmet
        titleTemplate="%s - React Boilerplate"
        defaultTitle="React Boilerplate"
        htmlAttributes={{ lang: i18n.language }}
      >
        <meta name="description" content="A React Boilerplate application" />
      </Helmet>
      <GlobalContainer>
        <Switch>
          {/* Protected Route */}
          <ProtectedRoute exact path={Path.HOME} component={HomePage} />
          <ProtectedRoute
            exact
            path={Path.CUSTOMERS}
            component={CustomerPage}
          />
          <ProtectedRoute
            exact
            path={Path.TIMESHEET}
            component={TimesheetPage}
          />
          <ProtectedRoute path={Path.PROJECTS} component={ProjectPage} />

          <ProtectedRoute
            exact
            path={Path.TIMESHEET_REPORT}
            component={TimesheetReportPage}
          />

          <ProtectedRoute exact path={Path.TEAM} component={TeamPage} />

          {/* Invite Member Page */}
          <Route
            path={`${Path.INVITE_MEMBER}/:invite_token`}
            component={InviteMemberPage}
          />

          {/* Common Route */}
          <Route exact path={Path.LOGIN} component={LoginPage} />

          {/* Put at end of Router list */}
          <Route component={NotFoundPage} />
        </Switch>
      </GlobalContainer>
      <GlobalStyle />
    </BrowserRouter>
  );
}
