import * as React from 'react';

// UI
import styled from 'styled-components/macro';
import { Box } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from 'react-router-dom';
import {
  faUserTie,
  faProjectDiagram,
  faUsers,
  faClock,
} from '@fortawesome/free-solid-svg-icons';
import Path from 'config/clientPath';

// Redux & Sagas
import { useDispatch } from 'react-redux';
import { useGlobalSlice } from 'app/pages/GlobalContainer/slice';

export function ASide() {
  const dispatch = useDispatch();
  const { setPageTitle } = useGlobalSlice().actions;

  return (
    <ASideContainer className="d-flex flex-column">
      <Brand display="flex" justifyContent="space-between" alignItems="center">
        <NavLink to={Path.HOME} className="brand-logo">
          Palette
        </NavLink>
        {/* <Button className="brand-toggle">
          <FontAwesomeIcon icon={faAngleLeft} />
        </Button> */}
      </Brand>
      <Menu display="flex" flexDirection="column">
        <NavLink
          to={Path.CUSTOMERS}
          onClick={() => {
            dispatch(setPageTitle('Customers'));
          }}
        >
          <FontAwesomeIcon icon={faUserTie} />
          Customer
        </NavLink>
        <NavLink
          to={Path.PROJECTS}
          onClick={() => {
            dispatch(setPageTitle('Projects'));
          }}
        >
          <FontAwesomeIcon icon={faProjectDiagram} />
          Projects
        </NavLink>
        <NavLink
          to={Path.MEMBERS}
          onClick={() => {
            dispatch(setPageTitle('Members'));
          }}
        >
          <FontAwesomeIcon icon={faUsers} />
          Members
        </NavLink>
        <NavLink
          to={Path.TEAM}
          onClick={() => {
            dispatch(setPageTitle('Teams'));
          }}
        >
          <FontAwesomeIcon icon={faUsers} />
          Teams
        </NavLink>
        <NavLink
          to={Path.TIMESHEET}
          onClick={() => {
            dispatch(setPageTitle('Timesheets'));
          }}
        >
          <FontAwesomeIcon icon={faClock} />
          Timesheets
        </NavLink>
      </Menu>
    </ASideContainer>
  );
}

const ASideContainer = styled.div`
  width: 265px;
  background: #1e1e2d;
  color: #fff;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 98;
`;

const Brand = styled(Box)`
  padding: 0 0 0 25px;
  margin-bottom: 25px;
  height: 65px;
  background-color: #1a1a27;
  color: #fff;
  .brand-logo {
    text-decoration: none;
    font-size: 24px;
    color: inherit;
  }
  .brand-toggle {
    color: inherit;
    font-size: 24px;
  }
`;

const Menu = styled(Box)`
  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    background-color: transparent;
    color: #fff;
    svg {
      margin-right: 10px;
    }
    height: 40px;
    padding: 25px 15px;
    margin: 0 10px;
    border-radius: 5px;
    &.active,
    &:hover {
      background-color: #eef0f8;
      color: #1f1e2e;
      cursor: pointer;
    }
  }
`;
