import React from 'react';
import Box from '@mui/material/Box';

import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';

import Grid from '@mui/material/Grid';
import { Controller } from 'react-hook-form';
import DatePicker from '@mui/lab/DatePicker';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import TextField, { TextFieldProps } from '@mui/material/TextField';

import styled from 'styled-components/macro';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { IProject } from '../slice/types';
import StyleWrapper from '../styles';
import { selectProjects } from '../slice/selectors';
import { useSelector } from 'react-redux';
import { P } from 'app/components/NotFoundPage/P';
import { RequestStatus } from 'constants/API';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { updateProjectRequest, useProjectsSlice } from '../slice';
import { useDispatch } from 'react-redux';
import DatePickerDisplay from './DatePickerDisplay';

const BootstrapDialog = styled(Dialog)`
  .MuDialogContent-root {
    padding: 20px;
  }

  .MuiFormGroup-root {
    display: inline-block;
  }

  .MuiDialogActions-root {
    display: flex;
    justify-content: space-between;
  }
`;
interface IModalProject {
  handleClose: () => void;
  projectToEdit: IProject | undefined;
}

export default function ModalProjectData(props: IModalProject) {
  const { projectToEdit, handleClose } = props;
  const dispatch = useDispatch();
  const { createProjectRequest } = useProjectsSlice().actions;

  const {
    listCustomer,
    getCustomerListStatus,
    projectStatusOptions,
    getProjectOptionsStatus,
  } = useSelector(selectProjects);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IProject>({
    defaultValues: { ...projectToEdit },
  });

  console.log([
    '🚀 ~ file: ModalProjectData.tsx ~ line 62 ~ ModalProjectData ~ projectToEdit',
    projectToEdit,
  ]);

  const handleModalSubmit = (values: IProject) => {
    const { start_date, end_date, ...rest } = values;
    // 🔴 DELETE later, these are the pending questions
    const pendingProps = {
      code: projectToEdit?.code || Math.random().toFixed(2),
    };
    const payload = {
      ...rest,
      start_date: moment(start_date).format('YYYY-MM-DD'),
      end_date: moment(end_date).format('YYYY-MM-DD'),
      ...pendingProps,
    };

    if (projectToEdit) {
      // update project request
      dispatch(updateProjectRequest(payload));
    } else {
      // create new project
      dispatch(createProjectRequest(payload));
    }
    handleClose();
  };

  return (
    <BootstrapDialog
      fullWidth
      maxWidth="md"
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={true}
    >
      <StyleWrapper>
        <form onSubmit={handleSubmit(handleModalSubmit)}>
          <DialogTitle sx={{ m: 0, p: 2 }}>
            {projectToEdit?.id ? 'Edit the project' : 'Create a new project'}
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: theme => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Grid container>
              <Grid item xs={12}>
                <Box>
                  <span className="field_label">Name</span>
                  <TextField
                    placeholder="Name"
                    variant="outlined"
                    {...register('name', {
                      required: 'This field is required.',
                      maxLength: 255,
                    })}
                    error={!!errors.name}
                    helperText={errors.name?.message}
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box>
                  <span className="field_label">Project type</span>
                  <RadioGroup row {...register('type')}>
                    <FormControlLabel
                      value="labo"
                      control={<Radio />}
                      label="Labo"
                    />
                    <FormControlLabel
                      value="projectBase"
                      control={<Radio />}
                      label="Project Base"
                    />
                  </RadioGroup>
                  {/* <span>{errors.scope?.message}</span> */}
                </Box>
                <Box>
                  {!listCustomer.length &&
                  getCustomerListStatus !== RequestStatus.REQUESTING ? (
                    <P>There is a problem fetching the list of customers.</P>
                  ) : (
                    <>
                      <span className="field_label">Customer</span>
                      <Controller
                        control={control}
                        rules={{ required: true }}
                        name="customer_id"
                        render={({ field: { onChange, value } }) => (
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={value}
                            onChange={onChange}
                            error={!!errors.customer_id}
                          >
                            {listCustomer.map(({ id, name }) => (
                              <MenuItem key={id} value={id}>
                                {name}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </>
                  )}
                </Box>
                <Box>
                  {!projectStatusOptions.length &&
                  getProjectOptionsStatus !== RequestStatus.REQUESTING ? (
                    <P>
                      There is a problem fetching the list of project status.
                    </P>
                  ) : (
                    <>
                      <span className="field_label">Status</span>
                      <Controller
                        control={control}
                        rules={{ required: true }}
                        name="status"
                        render={({ field: { onChange, value } }) => (
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={value}
                            onChange={onChange}
                            error={!!errors.status}
                          >
                            {projectStatusOptions.map(
                              ({ value, display_name }) => (
                                <MenuItem key={value} value={value}>
                                  {display_name}
                                </MenuItem>
                              ),
                            )}
                          </Select>
                        )}
                      />
                    </>
                  )}
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box>
                  <span className="field_label">Time</span>
                  <Controller
                    control={control}
                    rules={{ required: true }}
                    name="start_date"
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        mask="____.__.__"
                        disableFuture
                        views={['year', 'month', 'day']}
                        value={value}
                        onChange={onChange}
                        inputFormat="YYYY MMM DD"
                        renderInput={(params: TextFieldProps) => (
                          <DatePickerDisplay
                            {...params}
                            value={value}
                            error={!!errors.end_date}
                          />
                        )}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="end_date"
                    rules={{ required: 'This field is required' }}
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        disableFuture
                        mask="____.__.__"
                        views={['year', 'month', 'day']}
                        value={value}
                        onChange={onChange}
                        inputFormat="YYYY MMM DD"
                        renderInput={(params: TextFieldProps) => (
                          <DatePickerDisplay
                            {...params}
                            value={value}
                            error={!!errors.end_date}
                          />
                        )}
                      />
                    )}
                  />
                </Box>
                <Box>
                  <span className="field_label">Size</span>
                  <TextField
                    placeholder="Size"
                    variant="outlined"
                    type="number"
                    {...register('size', {
                      required: 'This field is required',
                    })}
                    error={!!errors.size}
                    helperText={errors.size?.message}
                  />
                </Box>
              </Grid>
              <Grid xs={12}>
                <Box>
                  <span className="field_label">Description</span>
                  <TextField
                    multiline
                    minRows={3}
                    {...register('description')}
                  />
                </Box>
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            <Button
              type="button"
              color="secondary"
              variant="contained"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button variant="contained" type="submit">
              Create
            </Button>
          </DialogActions>
        </form>
      </StyleWrapper>
    </BootstrapDialog>
  );
}
