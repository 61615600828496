import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { IconButton } from '@mui/material';

export function LanguageSwitch() {
  const { i18n } = useTranslation();
  const handleLanguageChange = (code: string) => {
    i18n.changeLanguage(code);
  };

  return (
    <LanguageSwitchContainer>
      <IconButton onClick={() => handleLanguageChange('en')}>🇺🇸</IconButton>
      <IconButton onClick={() => handleLanguageChange('vi')}>🇻🇳</IconButton>
      <IconButton onClick={() => handleLanguageChange('ja')}>🇯🇵</IconButton>
    </LanguageSwitchContainer>
  );
}

const LanguageSwitchContainer = styled.div`
  margin: 0 20px;
  .MuiIconButton-root {
    width: 46px;
    height: 46px;
  }
`;
