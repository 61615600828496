import { StatusCode } from 'constants/API';
import { call, put, takeEvery } from 'redux-saga/effects';
import APIs from 'APIs';
import {
  getListProjectRequest,
  getListProjectSuccess,
  getListProjectFail,
  getProjectOptionsRequest,
  getProjectOptionsSuccess,
  getProjectOptionsFail,
  createProjectRequest,
  createProjectSuccess,
  createProjectFail,
  updateProjectSuccess,
  updateProjectRequest,
  deleteProjectRequest,
  updateProjectFail,
  deleteProjectSuccess,
  deleteProjectFail,
} from '.';

const {
  getListProject,
  getProjectOptions,
  createProject,
  updateProject,
  deleteProject,
} = APIs;
function* getListProjectFlow({ payload }) {
  try {
    const response = yield call(getListProject, payload);
    if (response.status === StatusCode.SUCCESS_COMMON) {
      const { type } = getListProjectSuccess;
      yield put({
        type,
        payload: response.data,
      });
    } else {
      yield put({ type: getListProjectFail.type });
    }
  } catch (error) {
    yield put({ type: getListProjectFail.type });
  }
}

function* getProjectOptionsFlow() {
  try {
    const response = yield call(getProjectOptions);
    if (response.status === StatusCode.SUCCESS_COMMON) {
      const { type } = getProjectOptionsSuccess;
      yield put({
        type,
        payload: response.data.actions.POST,
      });
    }
  } catch (error) {
    yield put({ type: getProjectOptionsFail.type });
  }
}

function* createProjectFlow({ payload }) {
  try {
    const response = yield call(createProject, payload);
    if (
      response.status === StatusCode.SUCCESS_COMMON ||
      response.status === StatusCode.SUCCESS_CREATED
    ) {
      const { type } = createProjectSuccess;
      yield put({ type });
    }
  } catch (error) {
    yield put({ type: createProjectFail.type });
  }
}

function* updateProjectFlow({ payload }) {
  try {
    const response = yield call(updateProject, payload);
    if (
      response.status === StatusCode.SUCCESS_COMMON ||
      response.status === StatusCode.SUCCESS_CREATED
    ) {
      const { type } = updateProjectSuccess;
      yield put({ type });
    }
  } catch (error) {
    yield put({ type: updateProjectFail.type });
  }
}

function* deleteProjectFlow({ payload }) {
  try {
    const response = yield call(deleteProject, payload);
    if (response.status === StatusCode.NO_CONTENT) {
      const { type } = deleteProjectSuccess;
      yield put({ type });
    }
  } catch (error) {
    yield put({ type: deleteProjectFail.type });
  }
}

function* projectsWatcher() {
  yield takeEvery(getListProjectRequest, getListProjectFlow);
  yield takeEvery(getProjectOptionsRequest, getProjectOptionsFlow);
  yield takeEvery(createProjectRequest, createProjectFlow);
  yield takeEvery(updateProjectRequest, updateProjectFlow);
  yield takeEvery(deleteProjectRequest, deleteProjectFlow);
}

export default projectsWatcher;
