/**
 *
 * ProjectPage
 *
 */
import React, { memo } from 'react';

import moment from 'moment';
import Box from '@mui/system/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import { useHistory } from 'react-router';
import DatePicker from '@mui/lab/DatePicker';
import TableRow from '@mui/material/TableRow';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import ModalProjectData from './components/ModalProjectData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlusCircle } from '@fortawesome/free-solid-svg-icons';

import StyleWrapper from './styles';
import { ASide } from 'app/components/ASide';
import { NavBar } from 'app/components/NavBar';
import { selectProjects } from './slice/selectors';
import useHandleModal from './utils/useHandleModal';
import { Container } from 'app/components/Container';
import { IProject } from './slice/types';
import { useCustomerSlice } from '../CustomerPage/slice';
import { Wrapper, Content } from 'app/components/Wrapper';
import {
  deleteProjectRequest,
  getProjectOptionsRequest,
  useProjectsSlice,
} from './slice';

import {
  convertObjectToSearchString,
  convertSearchStringToObject,
} from 'utils/history';
import { convertUnixToDate } from 'utils/moment';
import DatePickerDisplay from './components/DatePickerDisplay';
import { RequestStatus } from 'constants/API';
import { setIsGlobalLoading } from '../GlobalContainer/slice';

interface Props {}

export const ProjectPage = memo((props: Props) => {
  const dispatch = useDispatch();
  const {
    location: { pathname, search },
    push: historyPush,
  } = useHistory();
  const {
    isModalOpen,
    createNewProject,
    projectToEdit,
    setProjectToEdit,
    handleModalClose,
  } = useHandleModal();

  const { getListProjectRequest, resetStatusOf } = useProjectsSlice().actions;
  const {
    listProject,
    getListProjectStatus,
    getProjectOptionsStatus,
    createProjectStatus,
    updateProjectStatus,
    deleteProjectStatus,
  } = useSelector(selectProjects);

  const { getCustomerListRequest } = useCustomerSlice().actions;

  const {
    reset: formReset,
    control,
    register,
    handleSubmit,
  } = useForm<IProject>({
    defaultValues: {
      start_date: undefined,
    },
  });

  const handleSearch = formValues => {
    const searchString = convertObjectToSearchString(formValues);
    if (searchString) {
      historyPush({
        pathname,
        search: searchString,
      });
      return;
    }
    dispatch(getListProjectRequest({}));
  };

  React.useEffect(() => {
    dispatch(getCustomerListRequest({}));
    dispatch(getProjectOptionsRequest());
  }, [dispatch, getCustomerListRequest]);

  const searchAction = React.useCallback(() => {
    if (search) {
      // Reset the form and make the API search call if the search exists
      const searchParams = convertSearchStringToObject(
        search,
      ) as Partial<IProject>;
      const time_start =
        convertUnixToDate(searchParams['time_start']) || moment().toDate();
      const time_end =
        convertUnixToDate(searchParams['time_end']) || moment().toDate();
      const newFormValues = {
        ...searchParams,
        time_start,
        time_end,
      };
      formReset(newFormValues);
      dispatch(getListProjectRequest(newFormValues));
    } else {
      dispatch(getListProjectRequest({}));
    }
  }, [dispatch, formReset, getListProjectRequest, search]);

  React.useEffect(() => {
    searchAction();
  }, [search, searchAction]);

  React.useEffect(() => {
    if (
      createProjectStatus === RequestStatus.REQUESTING ||
      updateProjectStatus === RequestStatus.REQUESTING ||
      deleteProjectStatus === RequestStatus.REQUESTING ||
      getListProjectStatus === RequestStatus.REQUESTING ||
      getProjectOptionsStatus === RequestStatus.REQUESTING
    ) {
      dispatch(setIsGlobalLoading(true));
    } else {
      dispatch(setIsGlobalLoading(false));
    }
  }, [
    createProjectStatus,
    updateProjectStatus,
    deleteProjectStatus,
    getListProjectStatus,
    getProjectOptionsStatus,
    dispatch,
  ]);

  React.useEffect(() => {
    if (createProjectStatus === RequestStatus.SUCCESS) {
      dispatch(resetStatusOf('createProjectStatus'));
      dispatch(getListProjectRequest({}));
    }
  }, [createProjectStatus, dispatch, getListProjectRequest, resetStatusOf]);

  React.useEffect(() => {
    if (updateProjectStatus === RequestStatus.SUCCESS) {
      dispatch(resetStatusOf('updateProjectStatus'));
      dispatch(getListProjectRequest({}));
    }
  }, [updateProjectStatus, dispatch, getListProjectRequest, resetStatusOf]);

  React.useEffect(() => {
    if (deleteProjectStatus === RequestStatus.SUCCESS) {
      dispatch(resetStatusOf('deleteProjectStatus'));
      dispatch(getListProjectRequest({}));
    }
  }, [deleteProjectStatus, dispatch, getListProjectRequest, resetStatusOf]);

  return (
    <StyleWrapper>
      <Container>
        <ASide />
        <Wrapper>
          <NavBar />
          <Content>
            <Paper className="block_search">
              <form onSubmit={handleSubmit(handleSearch)}>
                <Grid
                  container
                  columnSpacing={4}
                  justifyContent="space-between"
                >
                  <Grid item md={6}>
                    <Box>
                      <span className="field_label">Project type</span>
                      <TextField
                        placeholder="Project type"
                        variant="outlined"
                        {...register('type')}
                      />
                    </Box>
                    <Box>
                      <span className="field_label">Customer</span>
                      <TextField
                        placeholder="Customer"
                        variant="outlined"
                        {...register('customer')}
                      />
                    </Box>
                  </Grid>

                  <Grid item md={6}>
                    <Box>
                      <span className="field_label">Project Time</span>

                      <Controller
                        control={control}
                        name="start_date"
                        render={({ field: { onChange, value } }) => (
                          <DatePicker
                            disableFuture
                            views={['year', 'month', 'day']}
                            value={value}
                            onChange={onChange}
                            inputFormat="YYYY MMM DD"
                            renderInput={(params: TextFieldProps) => (
                              <DatePickerDisplay {...params} value={value} />
                            )}
                          />
                        )}
                      />
                      <Controller
                        control={control}
                        name="end_date"
                        render={({ field: { onChange, value } }) => (
                          <DatePicker
                            disableFuture
                            views={['year', 'month', 'day']}
                            value={value}
                            onChange={onChange}
                            inputFormat="YYYY MMM DD"
                            renderInput={(params: TextFieldProps) => (
                              <DatePickerDisplay {...params} value={value} />
                            )}
                          />
                        )}
                      />
                    </Box>
                    <Box>
                      <span className="field_label">Status</span>
                      <TextField
                        placeholder="Status"
                        variant="outlined"
                        {...register('status')}
                      />
                    </Box>
                  </Grid>
                </Grid>
                <Stack direction="row">
                  <Button type="submit" variant="contained" color="primary">
                    {' '}
                    Search{' '}
                  </Button>
                  <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    onClick={createNewProject}
                  >
                    <FontAwesomeIcon
                      icon={faPlusCircle}
                      style={{ marginRight: 5 }}
                    />
                    Create
                  </Button>
                </Stack>
              </form>
            </Paper>
            <Paper className="block_result">
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>No.</TableCell>
                    <TableCell>Code</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Customer</TableCell>
                    <TableCell>Project Type</TableCell>
                    <TableCell>Size (MM)</TableCell>
                    <TableCell>Start</TableCell>
                    <TableCell>End</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell></TableCell>
                    <TableCell size="small"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {listProject.map((project: IProject, index: number) => (
                    <TableRow key={project.id}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{project.code}</TableCell>
                      <TableCell>{project.name}</TableCell>
                      <TableCell>{project.customer_id}</TableCell>
                      <TableCell>{project.description}</TableCell>
                      <TableCell>{project.scope}</TableCell>
                      <TableCell>{project.start_date}</TableCell>
                      <TableCell>{project.end_date}</TableCell>
                      <TableCell>{project.status}</TableCell>
                      {/* pm_id,
                      image_url, */}
                      <TableCell align="right">
                        <Button
                          color="secondary"
                          size="small"
                          onClick={() => {
                            setProjectToEdit(project);
                          }}
                        >
                          Edit
                        </Button>
                        <Button
                          color="secondary"
                          size="small"
                          onClick={() => {
                            dispatch(deleteProjectRequest({ id: project.id }));
                          }}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
          </Content>
        </Wrapper>
      </Container>
      {isModalOpen && (
        <ModalProjectData
          projectToEdit={projectToEdit}
          handleClose={handleModalClose}
        />
      )}
    </StyleWrapper>
  );
});
