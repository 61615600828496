import styled from 'styled-components/macro';

const StyleWrapper = styled.div`
  .MuiBox-root {
    display: flex;
    &:first-child {
      margin-bottom: 20px;
    }
    .field_label {
      flex-basis: 90px;
      line-height: 38px;
      display: inline-block;
    }
    .MuiTextField-root {
      flex-grow: 1;
    }
  }
  .block_search {
    padding: 30px 46px;
    .MuiBox-root:first-child {
      margin-bottom: 20px;
    }
  }
`;

export default StyleWrapper;
