import { StatusCode } from 'constants/API';
import { call, put, takeLatest } from 'redux-saga/effects';
import APIs from 'APIs';
import _ from 'lodash';

import { createMemberRequest, createMemberSuccess, createMemberFail } from '.';

const { createMemberFromInvite } = APIs;

function* createMemberFlow({ payload }) {
  try {
    const response = yield call(createMemberFromInvite, payload);
    if (response.status === StatusCode.SUCCESS_CREATED) {
      yield put({
        type: createMemberSuccess.type,
        payload: response.data,
      });
    } else {
      yield put({
        type: createMemberFail.type,
        payload: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: createMemberFail.type,
      payload:
        _.get(error, 'response.data.detail') ||
        _.get(error, 'response.data') ||
        '',
    });
  }
}

function* teamWatcher() {
  yield takeLatest(createMemberRequest, createMemberFlow);
}

export default teamWatcher;
