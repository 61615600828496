import { IUserProfile } from 'app/pages/GlobalContainer/slice/types';
import moment from 'moment';
import {
  ACCESS_TOKEN_KEY,
  CACHED_URL,
  RECORD_START_TIME,
  RECORD_DURATION,
} from '../constants/localStorage';

const USER_PROFILE = 'USER_PROFILE';

// <Access Token>
export const getToken = () =>
  localStorage.getItem(ACCESS_TOKEN_KEY) ||
  sessionStorage.getItem(ACCESS_TOKEN_KEY);

export const setToken = (token: string) =>
  localStorage.setItem(ACCESS_TOKEN_KEY, token);

export const setTokenToSession = (token: string) =>
  sessionStorage.setItem(ACCESS_TOKEN_KEY, token);

export const removeToken = (): void => {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
  sessionStorage.removeItem(ACCESS_TOKEN_KEY);
};
export const isHavingToken = () => !!getToken();
// </Access Token>

// <User Profile>
export const getUserProfileFromLocalStorage = () => {
  const data = localStorage.getItem(USER_PROFILE);
  if (data) return JSON.parse(data);
  return undefined;
};
export const setUserProfileIntoLocalStorage = (userProfile: IUserProfile) =>
  localStorage.setItem(USER_PROFILE, JSON.stringify(userProfile));

export const getUserProfileFromSessionStorage = () => {
  const data = sessionStorage.getItem(USER_PROFILE);
  if (data) return JSON.parse(data);
  return undefined;
};
export const setUserProfileIntoSessionStorage = (userProfile: IUserProfile) =>
  sessionStorage.setItem(USER_PROFILE, JSON.stringify(userProfile));
export const removeUserProfileFromStorage = () => {
  localStorage.removeItem(USER_PROFILE);
  sessionStorage.removeItem(USER_PROFILE);
};
// </User Profile>

export const getCachedUrl = () => localStorage.getItem(CACHED_URL);

export const removeCachedUrl = () => localStorage.removeItem(CACHED_URL);

export const setRecordStartTime = (time: moment.Moment) =>
  localStorage.setItem(RECORD_START_TIME, time.format());
export const removeRecordStartTime = () =>
  localStorage.removeItem(RECORD_START_TIME);
export const getRecordStartTime = (): moment.Moment | null => {
  if (localStorage.getItem(RECORD_START_TIME)) {
    return moment(localStorage.getItem(RECORD_START_TIME));
  }
  return null;
};

export const setRecordDuration = (duration: string) =>
  localStorage.setItem(RECORD_DURATION, duration);
export const getRecordDuration = (): string | null =>
  localStorage.getItem(RECORD_DURATION);
export const resetRecordDuration = () =>
  localStorage.removeItem(RECORD_DURATION);
