import moment, { Moment } from 'moment';

export type Unix = number;

export const convertMomentToUnix = (momentObj?: Moment): Unix | undefined => {
  if (momentObj) return momentObj.unix();
  return undefined;
};

export const convertUnixToMoment = (unixTime?: Unix): Moment | undefined => {
  if (unixTime) return moment.unix(unixTime);
  return undefined;
};

export const convertUnixToDate = (unixTime?: Unix): Date | undefined => {
  if (unixTime) return convertUnixToMoment(unixTime)!.toDate();
};
