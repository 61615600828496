import { createSelector } from '@reduxjs/toolkit';
import { selectCustomer } from 'app/pages/CustomerPage/slice/selectors';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.projects || initialState;

export const selectProjects = createSelector(
  [selectSlice, selectCustomer],
  (projectsState, customersState) => ({
    ...projectsState,
    ...customersState,
  }),
);
